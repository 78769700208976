import React, { useState, useContext, useRef, useEffect } from "react";
import "../login/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo1.png";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { REGISTER_API } from "../../../AllApi/addStaffApi";
import UserContext from "../../../UserContext";
import LoginNavbar from "../login/LoginNavbar";

const ForgotPassword = () => {
  const { otp, setOTP, setResetEmail, resetEmail, otpPage, setOTPPage } =
    useContext(UserContext);
  const [email, setEmail] = useState("");
  const [enterOTP, setEnterOTP] = useState("");
  const [display, setDisplay] = useState(false);
  const [timerCount, setTimerCount] = useState(15);
  const [role, setRole] = useState("staff"); // State for selected role
  const timerRef = useRef(null); // Ref to store timer ID
  const navigate = useNavigate();

  const VerifyEmail = (e) => {
    e.preventDefault();
    if (otpPage === "true") {
      if (enterOTP) {
        if (parseInt(enterOTP) === otp) {
          setOTPPage("false");
          navigate("/newpassword");
        } else {
          toast.error(
            "The code you have entered is not correct, try again or re-send the code",
            {
              position: toast.POSITION.TOP_RIGHT,
               theme:"colored"
            }
          );
        }
      } else {
        toast.error("Please enter verification code", {
          position: toast.POSITION.TOP_RIGHT,
           theme:"colored"
        });
      }
    } else {
      if (email) {
        const data = {
          email: email,
          role: role,
        };
        REGISTER_API.VerifyEmail(data).then((resp) => {
          if (resp.status === 200) {
            document.getElementById("email_success").style.display = "block";
            setDisplay(true);
            const OTP = Math.floor(Math.random() * 9000 + 1000);
            setOTP(OTP);
            setResetEmail(email);
            setOTPPage("true");
            const data = {
              otp: OTP,
              email: email,
            };
            REGISTER_API.SendOTPEmail(data);
            // Start timer
            setTimerCount(15);
            setDisplay(true);
            timerRef.current = setTimeout(countdown, 1000);
          } else {
            document.getElementById("email_error").style.display = "block";
            document.getElementById("email_success").style.display = "none";
          }
        });
      } else {
        toast.error("Please enter your email", {
          position: toast.POSITION.TOP_RIGHT,
           theme:"colored"
        });
      }
    }
  };

  const countdown = () => {
    setTimerCount((prev) => {
      if (prev <= 1) {
        setDisplay(false);
        clearTimeout(timerRef.current); // Clear timer when done
        return 0;
      }
      return prev - 1;
    });

    if (timerCount > 1) {
      timerRef.current = setTimeout(countdown, 1000);
    }
  };

  const ResendOTP = () => {
    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const OTP = Math.floor(Math.random() * 9000 + 1000);
    setOTP(OTP);
    setOTPPage("true");

    const data = {
      otp: OTP,
      email: resetEmail,
    };

    REGISTER_API.SendOTPEmail(data)
      .then(() => {
        // Start the countdown after sending OTP
        setDisplay(true);
        setTimerCount(15);
        countdown(); // Start the countdown
      })
      .catch((error) => {
        toast.error("Failed to resend OTP. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current); // Clean up timer on component unmount
    };
  }, []);

  // Handle role change
  const handleRoleChange = (e) => {
    setRole(e.target.value); // Update role state
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid mainss">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div
              className="loginbg login-form"
              style={{ width: "-webkit-fill-available" }}
            >
              <p
                className="email_success text-light"
                id="email_success"
                style={{ display: "none" }}
              >
                Verification code sent to your email
              </p>
              <div className="form-bottom">
                 {/* <img
                  src={ieclogo}
                  style={{ borderRadius: "50%", height: "90px" }}
                  alt="Colege ERP"
                /> */}
                <form onSubmit={VerifyEmail}>
                  {otpPage === "false" ? (
                    <div>
                      <h5 className="text-center">FORGOT PASSWORD</h5>
                      <div className="form-group mt-4">
                        <input
                          id="rdbCollege"
                          type="radio"
                          name="role"
                          value="staff"
                          checked={role === "staff"}
                          className="me-1"
                          onChange={handleRoleChange}
                        />
                        <label htmlFor="rdbCollege" className="label">
                          Staff
                        </label>
                        <input
                          id="rdbTeacher"
                          type="radio"
                          name="role"
                          value="student"
                          checked={role === "student"}
                          className="mx-2 me-1"
                          onChange={handleRoleChange}
                        />
                        <label htmlFor="rdbStudent" className="label">
                          Student
                        </label>
                        <input
                          id="rdbGuardian"
                          type="radio"
                          name="role"
                          value="guardian"
                          checked={role === "guardian"}
                          className="mx-2 me-1"
                          onChange={handleRoleChange}
                        />
                        <label htmlFor="rdbGuardian" className="label">
                          Guardian
                        </label>
                      </div>
                      <div id="divDetails">
                        <div className="form-group mt-4">
                          <input
                            name="email"
                            value={email}
                            type="text"
                            id="txtUser"
                            className="form-control txtUser text-light"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <p
                          className="email_error text-light"
                          id="email_error"
                          style={{ display: "none" }}
                        >
                          User with this email does not exist!
                        </p>
                        <div className="d-grid mb-3 mt-4">
                          <button type="submit" className="btn btns">
                            Send OTP
                          </button>
                        </div>
                        <p className="text-end">
                          <NavLink
                            className="forgot text-light"
                            style={{ fontSize: "15px" }}
                            to="/login"
                          >
                            <span className="login-text">
                            <i className="fa-solid fa-right-to-bracket"></i>{" "}
                            Login
                            </span>
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h5 className="text-center">FORGOT PASSWORD</h5>
                      <div id="divDetails">
                        <div className="form-group mt-4 mb-4">
                          <input
                            type="text"
                            name="enterOTP"
                            onChange={(e) => setEnterOTP(e.target.value)}
                            value={enterOTP}
                            className="form-control txtUser text-light"
                            placeholder="Enter otp here..."
                          />
                        </div>
                        <div className="d-grid mb-3">
                          <button type="submit" className="btn btns text-light">
                            Submit OTP
                          </button>
                        </div>
                        <div className="d-flex mt-4" style={{justifyContent:"space-between"}}>
                          <p>
                            Didn't receive code ?
                            <NavLink
                              to="#"
                              color="link"
                              className="forgot-password text-light"
                              onClick={ResendOTP}
                            >
                              <span
                                className="otp-text mx-1"
                                style={{
                                  fontSize: ".8rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {display
                                  ? `Resent OTP in ${timerCount}s`
                                  : "Resend OTP"}
                              </span>
                            </NavLink>
                          </p>
                          <p>
                            <NavLink
                              className="text-light fw-bold"
                              style={{ fontSize: "15px",textDecoration:"none" }}
                              to="/login"
                            >
                              <span className="login-text">
                              <i className="fa-solid fa-right-to-bracket"></i>{" "}
                              Login
                              </span>
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
