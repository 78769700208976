import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilNotes,
  cilSpeedometer,
  cilChevronDoubleRight,
  cilGroup,
  cilSchool,
  cilCarAlt,
  cilHouse,
} from "@coreui/icons";
import {
  IoArrowForwardCircleOutline,
  IoSpeedometerOutline,
} from "react-icons/io5";
import { PiUsersThree } from "react-icons/pi";
import {
  FaRegCheckCircle,
  FaChartBar,
  FaChartLine,
  FaAngleDoubleRight,
  FaUserPlus,
  FaCalendarCheck,
  FaSitemap,
  FaMapSigns,
} from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { MdOutlineMap, MdOutlineMenuBook } from "react-icons/md";
import { CNavGroup, CNavItem } from "@coreui/react";
import { ClassNames } from "@emotion/react";
import { GoMortarBoard } from "react-icons/go";

const Sidebar_Menu = [
  // {
  //   component: CNavItem,
  //   name: "Dashboard",
  //   to: "/teacher/dashboard",
  //   icon: <IoSpeedometerOutline className="  nav-icon menu-icon"/>,
  // },
  // {
  //   component: CNavItem,
  //   name: "Profile Details",
  //   to: "/teacher/profile",
  //   icon: <IoSpeedometerOutline className=" nav-icon menu-icon"/>,
  // },
  {
    component: CNavGroup,
    name: "Student Information",
    icon: <FaUserPlus className=" nav-icon menu-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Student Details",
        to: "/admin/student/details",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Disabled Students",
        to: "/student/disablestudentslist",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Student Categories",
        to: "/category",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "Monthly Report",
      //   to:"/teacher/stuattendence/performance",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Monthly Report",
      //   to:"/teacher/stuattendencereport/monthly",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Subject Wise Report",
      //   to:"/teacher/stuattendencereport/bysubject",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "Behaviour Records",
    icon: <FaMapSigns className=" nav-icon menu-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Assign Incident",
        to: "/behaviour/studentincidents",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Incidents",
        to: "/behaviour/incidents",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Reports",
        to: "/behaviour/report",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Attendance",
    icon: <FaCalendarCheck className=" nav-icon menu-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Student Attendance",
        to: "/teacher/stuattendence/index",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "Daily Report",
      //   to:"/teacher/stuattendence/performance/daily",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Monthly Report",
      //   to:"/teacher/stuattendence/performance",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Monthly Report",
      //   to:"/teacher/stuattendencereport/monthly",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Subject Wise Report",
      //   to:"/teacher/stuattendencereport/bysubject",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Approve Leave",
      //   to: "/admin/approve_leave",
      //   icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "Examination",
    icon: <MdOutlineMap className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Upload Exam Marks",
        to: "/admin/subjectwise/upload/marks",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: "Academics",
  //   icon: <GoMortarBoard className=" nav-icon menu-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Section",
  //       to: "/admin/section/add",
  //       icon: <FaAngleDoubleRight className="sub-nav-icon" />
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Sub Section",
  //       to: "/admin/subsection/add",
  //       icon: <FaAngleDoubleRight className="sub-nav-icon" />
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Subject",
  //       to: "/admin/subject/add",
  //       icon: <FaAngleDoubleRight className="sub-nav-icon" />
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Class Timetable",
  //       to: "/hod/timetable/report",
  //       icon: <FaAngleDoubleRight className="sub-nav-icon" />
  //     },
  //   ],
  // },
  {
    component: CNavGroup,
    name: "Human Resource",
    icon: <FaSitemap className=" nav-icon menu-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Staff Directory",
        to: "/admin/staff",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Apply Leave",
        to: "/teacher/addleave",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Reports",
    icon: <FaChartLine className=" nav-icon menu-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Attendance Report",
        to: "/attendancereports/attendance",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Exams Report",
        to: "/student/examreports",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />,
      },
    ],
  },
];

export default Sidebar_Menu;
