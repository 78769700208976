import React, { useEffect, useState, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import Login from "./views/pages/login/Login";
import Page404 from "./views/pages/page404/Page404";
import DefaultLayout from "./layout/DefaultLayout";
import AddPassword from "./views/staff/AddPassword";
import UserContext from "./UserContext";
import ForgotPassword from "./views/pages/forgotPassword/ForgotPassword";
import ForgotPasswordForm from "./views/pages/forgotPassword/ForgotPasswordForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentLogin from "./views/pages/login/StudentLogin";
import GuardianLogin from "./views/pages/login/GuardianLogin";
import TeacherLogin from "./views/pages/login/TeacherLogin";

export const Loading = () => {
  return (
    <div className="spinner-box" id="Loading">
      <div className="circle-border">
        <div className="circle-core"></div>
      </div>
    </div>
  );
};

// ProtectedRoute: Ensures the user is logged in
const ProtectedRoute = ({ children }) => {
  const currentUser = localStorage.getItem("iec-active-user");
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return children;
};

// GuestRoute: Ensures the user is NOT logged in
const GuestRoute = ({ children }) => {
  const currentUser = localStorage.getItem("iec-active-user");
  const userRole = localStorage.getItem("user-role");
  if (currentUser) {
    return <Navigate to={`/${userRole}/dashboard`} />;
  }
  return children;
};

const App = () => {
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("iec-active-user")
  );
  const [userRole, setUserRole] = useState(localStorage.getItem("user-role"));
  const [otp, setOTP] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [otpPage, setOTPPage] = useState("false");
  const [message, setMessage] = useState("");
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
         theme:"colored",
         type:"info"
      });
      setMessage("");
    }
  }, [message]);

  return (
    <>
      <ToastContainer />
      <UserContext.Provider
        value={{
          currentUser,
          setCurrentUser,
          userRole,
          setUserRole,
          otp,
          setOTP,
          resetEmail,
          setResetEmail,
          otpPage,
          setOTPPage,
          message,
          setMessage,
          setAuthToken,
          authToken,
        }}
      >
        <Suspense fallback={<div>Loading.........</div>}>
          <Routes>
            {/* Default route */}
            <Route
              path="/"
              element={
                currentUser ? (
                  <Navigate to={`/${userRole}/dashboard`} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {/* Guest Routes: Only accessible when NOT logged in */}
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
            <Route
              path="/teacher/login"
              element={
                <GuestRoute>
                  <TeacherLogin />
                </GuestRoute>
              }
            />
            <Route
              path="/user/login"
              element={
                <GuestRoute>
                  <StudentLogin />
                </GuestRoute>
              }
            />
            <Route
              path="/parent/login"
              element={
                <GuestRoute>
                  <GuardianLogin />
                </GuestRoute>
              }
            />
            <Route
              path="/forgotpassword"
              element={
                <GuestRoute>
                  <ForgotPassword />
                </GuestRoute>
              }
            />
            <Route
              path="/newpassword"
              element={
                <GuestRoute>
                  <ForgotPasswordForm />
                </GuestRoute>
              }
            />

            {/* Protected Routes: Only accessible when logged in */}
            <Route
              path="/staff/addpassword"
              element={
                <ProtectedRoute>
                  <AddPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <DefaultLayout />
                </ProtectedRoute>
              }
            />

            {/* Catch-all route */}
            <Route path="/404" element={<Page404 />} />
          </Routes>
        </Suspense>
      </UserContext.Provider>
    </>
  );
};

export default App;
