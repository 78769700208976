import React, { useEffect, useState, useContext } from "react";
import "./style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo1.png";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { LOGIN_API } from "../../../AllApi/loginApi";
import UserContext from "../../../UserContext";
import LoginNavbar from "./LoginNavbar";
import { GiCheckMark } from "react-icons/gi";

const Login = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [login, setLogin] = useState("Staff");

  useEffect(() => {
    user.setOTPPage("false");
    user.setResetEmail("");
  }, []);
  // Login data change function
  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  // Login data submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loginData.username) {
      toast.error("Username is required..", {
        position: toast.POSITION.TOP_RIGHT,
         theme:"colored"
      });
    } else if (!loginData.password) {
      toast.error("Password is required..", {
        position: toast.POSITION.TOP_RIGHT,
         theme:"colored"
      });
    } else {
      LOGIN_API.Signin(loginData).then((response) => {
        if (response.status === 200) {
          user.setCurrentUser(response.data.staff_id);
          user.setUserRole(response.data.role);
          user.setAuthToken(response.token);
          user.setMessage(response.message);
          localStorage.setItem("user-role", response.data.role);
          localStorage.setItem("iec-active-user", response.data.staff_id);
          navigate(`/${response.role}/dashboard`);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
             theme:"colored"
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="container-fluid mainss">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div
              className="loginbg login-form"
              style={{ width: "-webkit-fill-available" }}
            >
              <div className="form-bottom">
                {/* <img
                  src={ieclogo}
                  style={{ borderRadius: "50%", height: "90px" }}
                  alt="Colege ERP"
                /> */}
                <h5 className="text-center text-light mb-4">{login} Login</h5>
                <form onSubmit={handleSubmit}>
                  <input type="hidden" name="ci_csrf_token" defaultValue="" />{" "}
                  <div className="form-group has-feedback">
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Username"
                      onChange={handleChange}
                      value={loginData.username}
                      className="form-username form-control input-text text-light"
                    />
                    <span className="fa fa-envelope form-control-feedback" />
                    <span className="text-danger" />
                  </div>
                  <div className="form-group has-feedback">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={loginData.password}
                      className="form-password form-control input-text text-light"
                      id="form-password"
                    />
                    <span className="fa fa-lock form-control-feedback" />
                    <span className="text-danger" />
                  </div>
                  <button type="submit" className="btn btns">
                    Sign In
                  </button>
                </form>

                <p style={{ marginTop: 15 }}>
                  <NavLink
                    to="/user/login"
                    style={{ color: "#535353" }}
                    className="forgot pull-right text-light"
                  >
                    {" "}
                    <i className="fa fa-user" /> User Login
                  </NavLink>{" "}
                </p>
                <p>
                  <NavLink className="forgot text-light" to="/forgotpassword">
                    <i className="fa fa-key" /> Forgot Password?
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
