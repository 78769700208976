import React, { useContext, useState } from "react";
import "../../../Css/login.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo1.png";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import UserContext from "../../../UserContext";
import { REGISTER_API } from "../../../AllApi/addStaffApi";
import LoginNavbar from "../login/LoginNavbar";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { resetEmail, setOTP, setResetEmail, setMessage } =
    useContext(UserContext);
  const [newPassword, setNewPassword] = useState("");

  const ResetPassword = (e) => {
    e.preventDefault();
    if(!newPassword){
      toast.error("Password field is required",{
        position:"top-right",
        theme:"colored"
      })
    }
    else{
      const data = {
        reset_password: newPassword,
        email: resetEmail,
      };
      REGISTER_API.ResetPassword(data).then(async (resp) => {
        if (resp.status === 200) {
          await setOTP("");
          await setResetEmail("");
          await setMessage(resp.message);
          navigate("/login");
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="container-fluid mainss">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div
              className="loginbg login-form"
              style={{ width: "-webkit-fill-available" }}
            >
              <div className="form-bottom">
                {/* <img
                  src={ieclogo}
                  style={{ borderRadius: "50%", height: "90px" }}
                  alt="Colege ERP"
                /> */}
                <CForm onSubmit={ResetPassword}>
                  <CRow>
                    <CCol md={12} sm={10} xs={10} className="login-section">
                      <label className="label">New Password</label>
                      <CInputGroup className="mb-2">
                        <CFormInput
                          type="text"
                          name="password"
                          className="input-text text-light"
                          onChange={(e) => setNewPassword(e.target.value)}
                          value={newPassword}
                        />
                      </CInputGroup>
                      <CRow className="mt-4">
                        <CCol lg={12}>
                          <CButton
                            // style={{ backgroundColor: "#C21807" }}
                            className="btn btns"
                            type="submit"
                          >
                            Reset Password
                          </CButton>
                        </CCol>
                      </CRow>
                      <p className="text-end mt-1">
                        <NavLink
                          className="forgot text-light"
                          style={{ fontSize: "15px" }}
                          to="/login"
                        >
                          <span className="login-text">
                            <i className="fa-solid fa-right-to-bracket"></i>{" "}
                            Login
                          </span>
                        </NavLink>
                      </p>
                    </CCol>
                  </CRow>
                </CForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
